import React from 'react';

class CheckerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { symbol: '', isShitcoin: undefined };
  }

  handleChange = (ev) => {
    document.querySelector('form button').classList.remove('animate__animated', 'animate__hinge', 'd-none');
    document.querySelector('.result-is-shitcoin').classList.add('d-none');
    this.setState({ symbol: ev.target.value }, () => {
      if (this.props.onFormChange) {
        this.props.onFormChange(this.state);
      }
    });
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    let legitCoins = [
      'btc',
      'bitcoin',
      'pitko',
      'btcusd',
      'btceur',
    ];
    let isShitcoin = ! legitCoins.includes(this.state.symbol.toLowerCase().trim());
    this.setState({ isShitcoin: isShitcoin }, () => {
      if (this.props.onCheckResult) {
        this.props.onCheckResult(this.state);
      }
    });

    if (isShitcoin) {
      var btn = document.querySelector('form button');
      btn.classList.add('animate__animated', 'animate__hinge');
      btn.addEventListener('animationend', () => {
        btn.classList.add('d-none');
        btn.classList.remove('animate__animated', 'animate__hinge');
        var res = document.querySelector('.result-is-shitcoin');
        res.classList.add('animate__animated', 'animate__fadeIn');
        res.classList.remove('d-none');
      });
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-floating text-dark mb-4">
          <input type="text" placeholder="Symbol" id="symbolInput" className="form-control form-control-lg" onChange={this.handleChange} required="required" />
          <label htmlFor="symbolInput">Enter coin symbol or name (e.g. BTC)</label>
        </div>
        <div className="d-grid">
          <button type="submit" className="btn btn-primary btn-lg button-do-check">Check</button>
          <button type="submit" className="btn btn-success btn-lg button-result-not-shitcoin">Not a shitcoin</button>
        </div>
        <div className="result-is-shitcoin d-none">Oh no <b>it is</b> a shitcoin!</div>
      </form>
    );
  }
}

export default CheckerForm;